import "./homepage.style.scss";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Inv1 from "./../../icons/Homepage Roasthof.jpg";
import Inv2 from "./../../icons/Homepage Estate.jpg";
import BannerHomeInverno from "./../../icons/home/home-banner-winter.jpg";
import BannerHomeEstate from "./../../icons/home/home-banner-summer.jpg";
import Slittino from "./../../icons/home/slittino.jpg";
import SuperCard from "./../../icons/home/super-card.jpg";
import Winter from "./../../icons/home/winter.jpg";
import Summer from "./../../icons/home/summer.jpg";
import { HOME_FAQ } from "../../components/constants/home-faq.constants";
import Button from "../../components/shared/button/button.component";
import AccordionCustom from "../../components/shared/accordion/accordion.component";
import Card from "../../components/shared/card/card.component";
import { HOMEPAGE_CARDS } from "../../components/shared/card/card.constants";

const Homepage = () => {
  const { t } = useTranslation();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 499px)" });

  const remappedHomeFaqs = () => {
    return HOME_FAQ.map((item) => ({
      ...item,
      header: t(`homepage.faq.${item.header}`),
      content: t(`homepage.faq.${item.content}`),
      linkTextDE: item.linkTextDE && t(`homepage.faq.${item.linkTextDE}`),
      linkTextEN: item.linkTextEN && t(`homepage.faq.${item.linkTextEN}`),
      linkTextIT: item.linkTextIT && t(`homepage.faq.${item.linkTextIT}`),
    }));
  };

  return (
    <div className="page-container">
      <div className="header"></div>
      <>
        <div className="body">
          {isBigScreen && (
            <>
              <img
                src={BannerHomeInverno}
                alt="banner homepage"
                className="banner__image"
              />
              <div className="intro">
                <h2>{t("homepage.intro.title")}</h2>
                <p>{t("homepage.intro.description")}</p>
              </div>
              <div className="banner-welcome">
                <div>
                  <img src={Inv1} alt="neustift" />
                  <p>{t("homepage.intro.roasthof")}</p>
                </div>
                <div>
                  <p>{t("homepage.intro.welcome")}</p>
                  <img src={Inv2} alt="neustift" />
                </div>
              </div>
              <Card cards={HOMEPAGE_CARDS} />
              <div className="super-card">
                <p className="super-card__title">
                  {t("homepage.super-card.title")}
                </p>
                <div className="super-card__box">
                  <img src={Slittino} alt="slittino" />
                  <div className="super-card__text">
                    <p>{t("homepage.super-card.subtitle")}</p>
                    <p>{t("homepage.super-card.description")}</p>
                    <Button
                      title={t(`homepage.cards.rooms-cta`)}
                      handleOnClick={() => null}
                      linkTo={"card"}
                      isFill
                    />
                  </div>
                  <img
                    src={SuperCard}
                    alt="superCard"
                    className="super-card__logo"
                  />
                </div>
              </div>
              <div className="seasons">
                <div className="seasons__box">
                  <p>{t("homepage.seasons.winter")}</p>
                  <img src={Winter} alt="winter" />
                  <Button
                    title={t(`homepage.seasons.cta`)}
                    handleOnClick={() => null}
                    linkTo={"seasons/#winter"}
                    isFill
                  />
                </div>
                <div className="seasons__box">
                  <p>{t("homepage.seasons.summer")}</p>
                  <img src={Summer} alt="winter" />
                  <Button
                    title={t(`homepage.seasons.cta`)}
                    handleOnClick={() => null}
                    linkTo={"seasons/#summer"}
                    isFill
                  />
                </div>
              </div>
              <div className="accordion">
                <h2>{t("apartments.faq-services")}</h2>
                <AccordionCustom items={remappedHomeFaqs()} />
              </div>
            </>
          )}
          {isTabletOrMobile && (
            <div className="banner">
              <img
                src={BannerHomeInverno}
                alt="banner homepage"
                className="banner__image"
              />
              <div className="intro--mobile">
                <div>
                  <p>{t("homepage.intro.welcome")}</p>
                  <p>{t("homepage.intro.roasthof")}</p>
                </div>
                <h2>{t("homepage.intro.title")}</h2>
              </div>
              <div className="intro-img">
                <img src={Inv1} alt="neustift" />
                <p>{t("homepage.intro.description-mobile-first")}</p>
                <img src={Inv2} alt="neustift" />
                <p>{t("homepage.intro.description-mobile-second")}</p>
              </div>
              <Card cards={HOMEPAGE_CARDS} />
              <div className="super-card-mobile">
                <p className="super-card-mobile__title">
                  {t("homepage.super-card.title")}
                </p>
                <div className="super-card-mobile__box">
                  <img src={Slittino} alt="slittino" />
                  <div className="super-card-mobile__text">
                    <p>{t("homepage.super-card.subtitle")}</p>
                    <p>{t("homepage.super-card.description")}</p>
                    <div>
                      <Button
                        title={t(`homepage.cards.rooms-cta`)}
                        handleOnClick={() => null}
                        linkTo={"card"}
                        isFill
                      />
                      <img src={SuperCard} alt="superCard" />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  isMobile
                    ? "seasons-miniature"
                    : "seasons-miniature tablet-seasons"
                }
              >
                <div className="seasons-miniature__box">
                  <p>{t("homepage.seasons.winter")}</p>
                  <img src={Winter} alt="winter" />
                  <Button
                    title={t(`homepage.seasons.cta`)}
                    handleOnClick={() => null}
                    linkTo={"seasons/#winter"}
                    isFill
                  />
                </div>
                <div className="seasons-miniature__box">
                  <p>{t("homepage.seasons.summer")}</p>
                  <img src={Summer} alt="winter" />
                  <Button
                    title={t(`homepage.seasons.cta`)}
                    handleOnClick={() => null}
                    linkTo={"seasons/#summer"}
                    isFill
                  />
                </div>
              </div>
              <div
                className={
                  isMobile
                    ? "accordion--mobile"
                    : "accordion--mobile accordion-tablet"
                }
              >
                <h2>{t("apartments.faq-services")}</h2>
                <AccordionCustom items={remappedHomeFaqs()} />
              </div>
            </div>
          )}
        </div>
      </>
      <span className="photo-copyright">
        Photo Copyright: TVB Stubai Tirol, Serlesbahnen Mieders/Mirja Geh, TVB
        Stubai Tirol/Andre Schönherr, Haselwanter Daniel
      </span>
    </div>
  );
};

export default Homepage;
