import { useMediaQuery } from "react-responsive";
import Button from "../../components/shared/button/button.component";
import Slittino from "./../../icons/home/slittino.jpg";
import SuperCard from "./../../icons/home/super-card.jpg";
import EstateBici from "./../../icons/seasons/Estate bici.jpg";
import EstateTrekking from "./../../icons/seasons/Estate passeggiate.jpg";
import InvernoSci from "./../../icons/seasons/Inverno sci.jpg";
import InvernoMisto from "./../../icons/seasons/Inverno altri sport.jpg";
import { useTranslation } from "react-i18next";
import "./seasons.style.scss";
import { LangugesEnum } from "../../components/header/components/menu/menu.enum";

const Seasons = (): JSX.Element => {
  const { t } = useTranslation();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });

  const selectedLang = localStorage.getItem("language");

  const trakkingEstateLinkTranslations = (): string => {
    switch (selectedLang) {
      case LangugesEnum.DE:
        return "https://www.stubai.at/aktivitaeten/wandern/";
      case LangugesEnum.EN:
        return "https://www.stubai.at/en/activities/hiking/";
      default:
        return "https://www.stubai.at/it/attivita/escursioni/";
    }
  };

  const bikeEstateLinkTranslations = (): string => {
    switch (selectedLang) {
      case LangugesEnum.DE:
        return "https://www.stubai.at/aktivitaeten/biken/";
      case LangugesEnum.EN:
        return "https://www.stubai.at/en/activities/biking/";
      default:
        return "https://www.stubai.at/it/attivita/ciclismo/";
    }
  };

  const skiInvernoLinkTranslations = (): string => {
    switch (selectedLang) {
      case LangugesEnum.DE:
        return "https://www.stubai.at/aktivitaeten/skifahren/";
      case LangugesEnum.EN:
        return "https://www.stubai.at/en/activities/skiing/";
      default:
        return "https://www.stubai.at/it/attivita/sciare/";
    }
  };

  const otherInvernoLinkTranslations = (): string => {
    switch (selectedLang) {
      case LangugesEnum.DE:
        return "https://www.stubai.at/aktivitaeten/winter-aktiv/";
      case LangugesEnum.EN:
        return "https://www.stubai.at/en/activities/winter-activ/";
      default:
        return "https://www.stubai.at/it/attivita/inverno-attivo/";
    }
  };

  return (
    <div className="seasons-page">
      <h2>{t("seasons.title")}</h2>
      <p className="seasons-page__description">{t("seasons.description")}</p>
      <>
        <div
          className={isBigScreen ? "super-card" : "super-card-mobile"}
          id="summer"
        >
          <p className="super-card__title">{t("seasons.summer-title")}</p>
          <div className="super-card__box first">
            <img src={Slittino} alt="slittino" />
            <div className="super-card__text">
              <p>{t("homepage.super-card.subtitle")}</p>
              <p>{t("seasons.super-card-desc")}</p>
              <Button
                title={t(`homepage.cards.rooms-cta`)}
                handleOnClick={() => null}
                linkTo={"card"}
                isFill
              />
            </div>
            {isBigScreen && (
              <img
                src={SuperCard}
                alt="superCard"
                className="super-card__logo"
              />
            )}
          </div>
          <div className="super-card__box reverse">
            {!isBigScreen && <img src={EstateTrekking} alt="estate trakking" />}

            <div className="super-card__text">
              <p>{t("seasons.estate-uno")}</p>
              <p>{t("seasons.estate-uno-desc")}</p>
              <a href={trakkingEstateLinkTranslations()} target="_blank">
                <Button
                  title={t(`homepage.cards.rooms-cta`)}
                  handleOnClick={() => null}
                  linkTo={""}
                  isFill
                />
              </a>
            </div>
            {isBigScreen && <img src={EstateTrekking} alt="estate trakking" />}
          </div>
          <div className="super-card__box normal">
            <img src={EstateBici} alt="estate bike" />

            <div className="super-card__text">
              <p>{t("seasons.estate-due")}</p>
              <p>{t("seasons.estate-due-desc")}</p>
              <a
                href={bikeEstateLinkTranslations()}
                target="_blank"
              >
                <Button
                  title={t(`homepage.cards.rooms-cta`)}
                  handleOnClick={() => null}
                  linkTo={""}
                  isFill
                />
              </a>
            </div>
          </div>
        </div>
        <div
          className={isBigScreen ? "super-card" : "super-card-mobile second"}
          id="winter"
        >
          <p className="super-card__title">{t("seasons.inverno-title")}</p>
          <div
            className={
              isBigScreen
                ? "super-card__box reverse"
                : "super-card__box reverse no-margin-top"
            }
          >
            {!isBigScreen && <img src={InvernoSci} alt="inverno sci" />}

            <div className="super-card__text">
              <p>{t("seasons.inverno-uno")}</p>
              <p>{t("seasons.inverno-uno-desc")}</p>
              <a
                href={skiInvernoLinkTranslations()}
                target="_blank"
              >
                <Button
                  title={t(`homepage.cards.rooms-cta`)}
                  handleOnClick={() => null}
                  linkTo={""}
                  isFill
                />
              </a>
            </div>
            {isBigScreen && <img src={InvernoSci} alt="inverno sci" />}
          </div>
          <div
            className={
              isBigScreen
                ? "super-card__box normal"
                : "super-card__box normal last"
            }
          >
            <img src={InvernoMisto} alt="sport invernali" />

            <div className="super-card__text">
              <p>{t("seasons.inverno-due")}</p>
              <p>{t("seasons.inverno-due-desc")}</p>
              <a
                href={otherInvernoLinkTranslations()}
                target="_blank"
              >
                <Button
                  title={t(`homepage.cards.rooms-cta`)}
                  handleOnClick={() => null}
                  linkTo={""}
                  isFill
                />
              </a>
            </div>
          </div>
        </div>
      </>
      <span className="photo-copyright">
        Photo Copyright: TVB Stubai Tirol/Andre Schönherr, Andre Schönherr, TVB
        Stubai Tirol/Max Dräger
      </span>
    </div>
  );
};

export default Seasons;
