import SerlesPiantina from "../../icons/apartments/serles/Serles.jpg";
import Serles1 from "../../icons/apartments/serles/Serles-1.jpg";
import Serles2 from "../../icons/apartments/serles/Serles-2.jpg";
import Serles3 from "../../icons/apartments/serles/Serles-3.jpg";
import Serles4 from "../../icons/apartments/serles/Serles-4.jpg";
import Serles5 from "../../icons/apartments/serles/Serles-5.jpg";
import Serles6 from "../../icons/apartments/serles/Serles-6.jpg";
import Serles7 from "../../icons/apartments/serles/Serles-7.jpg";
import Serles8 from "../../icons/apartments/serles/Serles-8.jpg";
import Serles9 from "../../icons/apartments/serles/Serles-9.jpg";

import BurgstallPiantina from "../../icons/apartments/burgstall/Burgstall.jpg";
import Burgstall1 from "../../icons/apartments/burgstall/Burgstall-1.jpg";
import Burgstall2 from "../../icons/apartments/burgstall/Burgstall-2.jpg";
import Burgstall3 from "../../icons/apartments/burgstall/Burgstall-3.jpg";
import Burgstall4 from "../../icons/apartments/burgstall/Burgstall-4.jpg";
import Burgstall5 from "../../icons/apartments/burgstall/Burgstall-5.jpg";

import HabichtPiantina from "../../icons/apartments/habict/Habicht.jpg";
import Habicht1 from "../../icons/apartments/habict/Habicht-1.jpg";
import Habicht2 from "../../icons/apartments/habict/Habicht-2.jpg";
import Habicht3 from "../../icons/apartments/habict/Habicht-3.jpg";
import Habicht4 from "../../icons/apartments/habict/Habicht-4.jpg";
import Habicht5 from "../../icons/apartments/habict/Habicht-5.jpg";

import KreuzjochPiantina from "../../icons/apartments/kreuzjoch/Kreuzjoch.jpg";
import Kreuzjoch1 from "../../icons/apartments/kreuzjoch/Kreuzjoch-1.jpg";
import Kreuzjoch2 from "../../icons/apartments/kreuzjoch/Kreuzjoch-2.jpg";
import Kreuzjoch3 from "../../icons/apartments/kreuzjoch/Kreuzjoch-3.jpg";
import Kreuzjoch4 from "../../icons/apartments/kreuzjoch/Kreuzjoch-4.jpg";

import Casa from "../../icons/Casa.jpg";
import Casa1 from "../../icons/Casa (1).jpg";
import Casa2 from "../../icons/Casa (2).jpg";
import Palestra from "../../icons/Palestra.jpg";
import Palestra1 from "../../icons/Palestra (1).jpg";
import PingPong from "../../icons/Ping Pong.jpg";
import Terrazza from "../../icons/Terrazza.jpg";
import Vista from "../../icons/Vista.jpg";
import Giochi from "../../icons/Parco giochi.jpg";

import { IAccordionItems } from "../../components/shared/accordion/accordion.interface";

export const SERLES_IMG = [
  {
    original: SerlesPiantina,
  },
  {
    original: Serles1,
  },
  {
    original: Serles2,
  },
  {
    original: Serles3,
  },
  {
    original: Serles4,
  },
  {
    original: Serles5,
  },
  {
    original: Serles6,
  },
  {
    original: Serles7,
  },

  {
    original: Serles8,
  },

  {
    original: Serles9,
  },
  {
    original: Casa,
  },
  {
    original: Casa1,
  },
  {
    original: Casa2,
  },
  {
    original: Palestra,
  },
  {
    original: Palestra1,
  },
  {
    original: PingPong,
  },
  {
    original: Terrazza,
  },
  {
    original: Vista,
  },
  {
    original: Giochi,
  },
];

export const HABICHT_IMG = [
  {
    original: HabichtPiantina,
  },
  {
    original: Habicht1,
  },
  {
    original: Habicht2,
  },
  {
    original: Habicht3,
  },
  {
    original: Habicht4,
  },
  {
    original: Habicht5,
  },
  {
    original: Casa,
  },
  {
    original: Casa1,
  },
  {
    original: Casa2,
  },
  {
    original: Palestra,
  },
  {
    original: Palestra1,
  },
  {
    original: PingPong,
  },
  {
    original: Terrazza,
  },
  {
    original: Vista,
  },
  {
    original: Giochi,
  },
];

export const KREUZJOCH_IMG = [
  {
    original: KreuzjochPiantina,
  },
  {
    original: Kreuzjoch1,
  },
  {
    original: Kreuzjoch2,
  },
  {
    original: Kreuzjoch3,
  },
  {
    original: Kreuzjoch4,
  },
  {
    original: Casa,
  },
  {
    original: Casa1,
  },
  {
    original: Casa2,
  },
  {
    original: Palestra,
  },
  {
    original: Palestra1,
  },
  {
    original: PingPong,
  },
  {
    original: Terrazza,
  },
  {
    original: Vista,
  },
  {
    original: Giochi,
  },
];

export const BURGSTALL_IMG = [
  {
    original: BurgstallPiantina,
  },
  {
    original: Burgstall1,
  },
  {
    original: Burgstall2,
  },
  {
    original: Burgstall3,
  },
  {
    original: Burgstall4,
  },
  {
    original: Burgstall5,
  },
  {
    original: Casa,
  },
  {
    original: Casa1,
  },
  {
    original: Casa2,
  },
  {
    original: Palestra,
  },
  {
    original: Palestra1,
  },
  {
    original: PingPong,
  },
  {
    original: Terrazza,
  },
  {
    original: Vista,
  },
  {
    original: Giochi,
  },
];

export const BURGSTALL_FAQ: IAccordionItems[] = [
  {
    header: "firstTitle",
    points: [
      "service1",
      "service2",
      "service3",
      "service4",
      "service5",
      "service6",
      "service7",
      "service8",
      "service9",
      "service10",
    ],
    pointPrefiix: "apartments.faq.burgstall",
  },
  {
    header: "secondTitle",
    points: ["price1", "price2", "price3", "price4"],
    pointPrefiix: "apartments.faq.burgstall",
  },
];

export const HABICHT_FAQ: IAccordionItems[] = [
  {
    header: "firstTitle",
    points: [
      "service1",
      "service2",
      "service3",
      "service4",
      "service5",
      "service6",
      "service7",
      "service8",
      "service9",
      "service10",
    ],
    pointPrefiix: "apartments.faq.habicht",
  },
  {
    header: "secondTitle",
    points: ["price1", "price2", "price3", "price4"],
    pointPrefiix: "apartments.faq.habicht",
  },
];

export const SERLES_FAQ: IAccordionItems[] = [
  {
    header: "firstTitle",
    points: [
      "service1",
      "service2",
      "service3",
      "service4",
      "service5",
      "service6",
      "service7",
      "service8",
      "service9",
      "service10",
      "service11",
    ],
    pointPrefiix: "apartments.faq.serles",
  },
  {
    header: "secondTitle",
    points: ["price1", "price2", "price3", "price4"],
    pointPrefiix: "apartments.faq.serles",
  },
];

export const SERVICES_IMG = [
  {
    original: Casa,
  },
  {
    original: Casa1,
  },
  {
    original: Casa2,
  },
  {
    original: Palestra,
  },
  {
    original: Palestra1,
  },
  {
    original: PingPong,
  },
  {
    original: Terrazza,
  },
  {
    original: Vista,
  },
  {
    original: Giochi,
  },
];
