import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import { IAccordionCustom } from "./accordion.interface";
import chevronDown from "../../../icons/shared/chevron-down.svg";

import "./accordion.style.scss";
import { useTranslation } from "react-i18next";

const AccordionCustom = ({ items }: IAccordionCustom): JSX.Element => {
  const { t } = useTranslation();

  /**
   * @type {React.ExoticComponent<import('@szhsin/react-accordion').AccordionItemProps>}
   */
  // @ts-ignore
  const AccordionItem = ({ header, ...rest }) => (
    <Item
      {...rest}
      header={
        <>
          {header}
          <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
        </>
      }
    />
  );

  return (
    <Accordion>
      {items.map(
        (
          {
            header,
            content,
            points,
            pointPrefiix,
            urlDE,
            urlEN,
            urlIT,
            linkTextDE,
            linkTextEN,
            linkTextIT,
          },
          i
        ) => (
          <AccordionItem header={header} key={i}>
            {points &&
              points.length > 0 &&
              points.map((point) => (
                <ul>
                  <li>{t(`${pointPrefiix}.${point}`)}</li>
                </ul>
              ))}
            {content && content}
            {urlDE && linkTextDE && (
              <a href={urlDE} target="_blank">
                {linkTextDE} /
              </a>
            )}
            {urlEN && linkTextEN && (
              <a href={urlEN} target="_blank">
                {linkTextEN} /
              </a>
            )}
            {urlIT && linkTextIT && (
              <a href={urlIT} target="_blank">
                {linkTextIT}
              </a>
            )}
          </AccordionItem>
        )
      )}
    </Accordion>
  );
};

export default AccordionCustom;
