import Button from "../button/button.component";
import uuid from "react-uuid";
import FamilySnow from "../../../icons/family/family_snow.jpg";
import Apartment from "../../../icons/apartments/serles/Serles-1.jpg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import "./card.style.scss";
import CardResposiveComponent from "./card-mobile/card-responsive.component";
import { ICard } from "./card.interface";

export interface ICardProps {
  cards: ICard[];
}

const Card = ({cards}: ICardProps): JSX.Element => {
  const { t } = useTranslation();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  return (
      <div className={isBigScreen ? "card" : "card-responsive"} key={uuid()}>
        {isBigScreen &&
          cards.map((card) => {
            const img =
              card.title === "us"
                ? FamilySnow
                : card.title === "apartments"
                ? Apartment
                : Apartment;
            return (
              <div
                key={card.title}
                className={card.isTextRight ? "card--right" : "card--left"}
              >
                <span className="black-rect"></span>
                <div className="card__text">
                  <p className="card__text--title" key={uuid()}>
                    {t(`homepage.cards.${card.title}`)}
                  </p>
                  <span className="card__text--description" key={uuid()}>
                    {t(`homepage.cards.${card.description}`)}
                  </span>
                  {card.linkTo && <Button
                    key={uuid()}
                    title={t(`homepage.cards.${card.cta}`)}
                    handleOnClick={() => null}
                    linkTo={card.linkTo}
                    className="card-button"
                    isFill
                  />}
                </div>
                <img
                  src={img}
                  alt={t(`homepage.cards.${card.title}`)}
                  key={uuid()}
                />
                <span className="blue-rect"></span>
              </div>
            );
          })}
        {isTabletOrMobile && <CardResposiveComponent cards={cards}/>}
      </div>
  );
};

export default Card;
