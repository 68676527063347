import { useState } from "react";
import { classNames } from "../../../../generic/class-names.utils";
import { LANGUAGES } from "../menu/menu.constants";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

const Language = (): JSX.Element => {
  const { i18n } = useTranslation();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    LANGUAGES[0]
  );

  const onChangeLanguage = (lang: string) => {    
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem('language', lang);
  };

  const responsiveClasses = classNames({
    "language": true,
    "language--desktop": isBigScreen,
    "language--mobile": isTabletOrMobile,
  });
console.log(selectedLanguage);

  return (
    <div className={responsiveClasses}>
      {LANGUAGES.map((language, i) => {
        const langClasses = classNames({
          "language-items": true,
          "language-items--selected": selectedLanguage === language,
        });
        return (
          <div key={uuid()} className={langClasses}>
            <span onClick={() => onChangeLanguage(language)}>{language.toUpperCase()}</span>
            {i !== 2 && <span>/</span>}
          </div>
        );
      })}
    </div>
  );
};

export default Language;
