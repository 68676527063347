import SerlesCover from "../../icons/apartments/SERLES.svg";
import BurgstallCover from "../../icons/apartments/BURGSTALL.svg";
import HabichtCover from "../../icons/apartments/HABICHT.svg";
import KreuzjochCover from "../../icons/apartments/KREUZJOCH.svg";
import Giochi from "../../icons//Parco giochi.jpg";
import Casa1 from "../../icons/Casa (1).jpg";
import "./apartments.style.scss";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import ImageGallery from "react-image-gallery";
import {
  BURGSTALL_FAQ,
  BURGSTALL_IMG,
  HABICHT_FAQ,
  HABICHT_IMG,
  KREUZJOCH_IMG,
  SERLES_FAQ,
  SERLES_IMG,
  SERVICES_IMG,
} from "./apartments.constants";
import AccordionCustom from "../../components/shared/accordion/accordion.component";
import Button from "../../components/shared/button/button.component";
import { useState } from "react";
import BookingModal from "../../components/booking-modal/booking-modal.component";
import chevronDown from "../../icons/shared/chevron-down.svg";
import { HOME_FAQ } from "../../components/constants/home-faq.constants";

const Apartments = (): JSX.Element => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery({ query: "(max-width: 499px)" });
  const isTablet = useMediaQuery({ query: "(max-width: 1299px)" });

  const burgstallFaqs = () => {
    return BURGSTALL_FAQ.map((item) => ({
      ...item,
      header: t(`apartments.faq.burgstall.${item.header}`),
      points: item.points,
      content: item.content
        ? t(`apartments.faq.burgstall.${item.content}`)
        : "",
    }));
  };

  const habichtFaqs = () => {
    return HABICHT_FAQ.map((item) => ({
      ...item,
      header: t(`apartments.faq.habicht.${item.header}`),
      points: item.points,
      content: item.content ? t(`apartments.faq.habicht.${item.content}`) : "",
    }));
  };

  const serlesFaqs = () => {
    return SERLES_FAQ.map((item) => ({
      ...item,
      header: t(`apartments.faq.serles.${item.header}`),
      points: item.points,
      content: item.content ? t(`apartments.faq.serles.${item.content}`) : "",
    }));
  };

  const remappedHomeFaqs = () => {
    return HOME_FAQ.map((item) => ({
      ...item,
      header: t(`homepage.faq.${item.header}`),
      content: t(`homepage.faq.${item.content}`),
      linkTextDE: item.linkTextDE && t(`homepage.faq.${item.linkTextDE}`),
      linkTextEN: item.linkTextIT && t(`homepage.faq.${item.linkTextEN}`),
      linkTextIT: item.linkTextIT && t(`homepage.faq.${item.linkTextIT}`),
    }));
  };

  if (isModalOpen)
    return (
      <BookingModal
        handleCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
    );
  return (
    <div
      className={
        isMobile
          ? "apartment-container-miniature"
          : isTablet
          ? "apartment-container-miniature tablet"
          : "apartment-container"
      }
    >
      <h2>{t("apartments.title")}</h2>
      <p className="apartment-subtitle">{t("apartments.subtitle")}</p>
      <p className="apartment-description">{t("apartments.description")}</p>
      <div className="apartments-box">
        <div
          className={
            !isMobile && !isTablet ? "apartment" : "apartment-miniature"
          }
          id="burgstall"
        >
          <img src={BurgstallCover} className="cover-img" />
          <ImageGallery
            items={BURGSTALL_IMG}
            showPlayButton={false}
            showBullets
          />
        </div>
        <AccordionCustom items={burgstallFaqs()} />
        <div className="services-cross">
          <a href="#children">
            {t("apartments.children-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#general-services">
            {t("apartments.home-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#faq-services">
            {t("apartments.faq-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <button className="booking-btn" onClick={() => setIsModalOpen(true)}>
            {t(`cta.book`)}
          </button>
          <a
            href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
            target="_blank"
          >
            <button className="booking-btn" onClick={() => null}>
              {t(`cta.prenota`)}
            </button>
          </a>
        </div>

        <div
          className={
            !isMobile && !isTablet ? "apartment reverse" : "apartment-miniature"
          }
          id="habicht"
        >
          <img src={HabichtCover} className="cover-img" />
          <ImageGallery
            items={HABICHT_IMG}
            showPlayButton={false}
            showBullets
          />
        </div>
        <AccordionCustom items={habichtFaqs()} />
        <div className="services-cross">
          <a href="#children">
            {t("apartments.children-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#general-services">
            {t("apartments.home-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#faq-services">
            {t("apartments.faq-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <button className="booking-btn" onClick={() => setIsModalOpen(true)}>
            {t(`cta.book`)}
          </button>
          <a
            href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
            target="_blank"
          >
            <button className="booking-btn" onClick={() => null}>
              {t(`cta.prenota`)}
            </button>
          </a>
        </div>
        <div
          className={
            !isMobile && !isTablet ? "apartment" : "apartment-miniature"
          }
          id="kreuzjoch"
        >
          <img src={KreuzjochCover} className="cover-img" />
          <ImageGallery
            items={KREUZJOCH_IMG}
            showPlayButton={false}
            showBullets
          />
        </div>
        <AccordionCustom items={habichtFaqs()} />
        <div className="services-cross">
          <a href="#children">
            {t("apartments.children-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#general-services">
            {t("apartments.home-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#faq-services">
            {t("apartments.faq-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <button className="booking-btn" onClick={() => setIsModalOpen(true)}>
            {t(`cta.book`)}
          </button>
          <a
            href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
            target="_blank"
          >
            <button className="booking-btn" onClick={() => null}>
              {t(`cta.prenota`)}
            </button>
          </a>
        </div>
        <div
          className={
            !isMobile && !isTablet ? "apartment reverse" : "apartment-miniature"
          }
          id="serles"
        >
          <img src={SerlesCover} className="cover-img" />
          <ImageGallery items={SERLES_IMG} showPlayButton={false} showBullets />
        </div>
        <AccordionCustom items={serlesFaqs()} />
        <div className="services-cross">
          <a href="#children">
            {t("apartments.children-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#general-services">
            {t("apartments.home-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <a href="#faq-services">
            {t("apartments.faq-services")}
            <img
              className="chevron-down"
              src={chevronDown}
              alt="Chevron Down"
            />
          </a>
          <button className="booking-btn" onClick={() => setIsModalOpen(true)}>
            {t(`cta.book`)}
          </button>
          <a
            href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
            target="_blank"
          >
            <button className="booking-btn" onClick={() => null}>
              {t(`cta.prenota`)}
            </button>
          </a>
        </div>
      </div>
      <div id="children">
        <div
          className={
            !isMobile ? "children-section" : "children-section-miniature"
          }
        >
          <img src={Giochi} alt="children" />
          <div>
            <h2>{t("apartments.children.title")}</h2>
            <p>{t("apartments.children.subtitle")}</p>
            <p>{t("apartments.children.bullet-title")}</p>
            <ul>
              <li>{t("apartments.children.bullet1")}</li>
              <li>{t("apartments.children.bullet2")}</li>
              <li>{t("apartments.children.bullet3")}</li>
              <li>{t("apartments.children.bullet4")}</li>
              <li>{t("apartments.children.bullet5")}</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="general-services">
        <div
          className={!isMobile ? "house-section" : "house-section-miniature"}
        >
          <div className="services-gallery">
            <ImageGallery
              items={SERVICES_IMG}
              showPlayButton={false}
              showBullets
            />
          </div>
          <div>
            <h2>{t("apartments.house.title")}</h2>
            <ul>
              <li>{t("apartments.house.bullet1")}</li>
              <li>{t("apartments.house.bullet2")}</li>
              <li>{t("apartments.house.bullet3")}</li>
              <li>{t("apartments.house.bullet4")}</li>
              <li>{t("apartments.house.bullet5")}</li>
              <li>{t("apartments.house.bullet6")}</li>
              <li>{t("apartments.house.bullet7")}</li>
              <li>{t("apartments.house.bullet8")}</li>
              <li>{t("apartments.house.bullet9")}</li>
              <li>{t("apartments.house.bullet10")}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="link-top">
        {t("apartments.top")}
        <a href="#burgstall">
          Burgstall
          <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
        </a>
        <a href="#habicht">
          Habicht
          <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
        </a>
        <a href="#kreuzjoch">
          Kreuzjoch
          <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
        </a>
        <a href="#serles">
          Serles
          <img className="chevron-down" src={chevronDown} alt="Chevron Down" />
        </a>
      </div>
      <div id="faq-services">
        <h2>{t("apartments.faq-services")}</h2>
        <AccordionCustom items={remappedHomeFaqs()} />
      </div>
      <span className="photo-copyright">
        Photo Copyright: Haselwanter Daniel
      </span>
    </div>
  );
};

export default Apartments;
