import { useTranslation } from "react-i18next";
import GletscherImg from "./../../icons/superCard/Gletscher.jpg";
import MiedersImg from "./../../icons/superCard/Mieders.jpg";
import SerlesImg from "./../../icons/superCard/Serlesbahn.jpg";
import TransportImg from "./../../icons/superCard/Transport.jpg";
import { useMediaQuery } from "react-responsive";

import "./superCard.style.scss";

const SuperCard = (): JSX.Element => {
  const { t } = useTranslation();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });

  return (
    <div className={isBigScreen ? "superCard" : "superCard-mobile"}>
      <div className="superCard-container">
        <div className="superCard-text">
          <h2>Stubai Super Card</h2>
          <p>{t("superCard.one")}</p>
          <p>{t("superCard.two")}</p>
          <p>{t("superCard.three")}</p>
        </div>
        <iframe
          width="500"
          height="300"
          src="https://www.youtube.com/embed/ounXdmwZLbY"
          frameBorder="0"
          allowFullScreen
        />
      </div>

      <div className="box">
        <span className="blue-rect" />
        <img src={GletscherImg} alt="img" className="box-img" />
        <div>
          <h2>{t("superCard.title1")}</h2>
          <ul>
            <li>{t("superCard.lia1")}</li>
            <li>{t("superCard.lia2")}</li>
            <li>{t("superCard.lia3")}</li>
            <li>{t("superCard.lia4")}</li>
            <li>{t("superCard.lia5")}</li>
          </ul>
        </div>
        <span className="black-rect" />
      </div>
      <div className="box reverse">
        <span className="blue-rect" />
        <img src={TransportImg} alt="img" className="box-img" />
        <div>
          <h2>{t("superCard.title2")}</h2>
          <ul>
            <li>{t("superCard.lib1")}</li>
            <li>{t("superCard.lib2")}</li>
          </ul>
        </div>
        <span className="black-rect" />
      </div>
      <div className="box">
        <span className="blue-rect" />

        <img src={MiedersImg} alt="img" className="box-img" />
        <div>
          <h2>{t("superCard.title3")}</h2>
          <ul>
            <li>{t("superCard.lic1")}</li>
            <li>{t("superCard.lic2")}</li>
          </ul>
        </div>
        <span className="black-rect" />
      </div>
      <div className="box reverse">
        <span className="blue-rect" />

        <img src={SerlesImg} alt="img" className="box-img" />
        <div>
          <h2>{t("superCard.title4")}</h2>
          <ul>
            <li>{t("superCard.lid1")}</li>
            <li>{t("superCard.lid2")}</li>
            <li>{t("superCard.lid3")}</li>
            <li>{t("superCard.lid4")}</li>
            <li>{t("superCard.lid5")}</li>
            <li>{t("superCard.lid6")}</li>
            <li>{t("superCard.lid7")}</li>
            <li>{t("superCard.lid8")}</li>
            <li>{t("superCard.lid9")}</li>
            <li>{t("superCard.lid10")}</li>
          </ul>
        </div>
        <span className="black-rect" />
      </div>
      <span className="photo-copyright">
        Photo Copyright: TVB Stubai Tirol, Andre Schönherr, TVB
        Stubai Tirol/Andre Schönherr, Serlesbahnen Mieders/Mirja Geh
      </span>
    </div>
  );
};

export default SuperCard;
