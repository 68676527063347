import "./App.scss";
import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/Homepage/homepage.component";
import Contact from "./pages/Contact/contact.component";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import MobileMenu from "./components/header/components/mobile-menu/mobile-menu.component";
import MenuComponent from "./components/header/components/menu/menu.component";
import Footer from "./components/footer/footer.component";
import Apartments from "./pages/Apartments/apartments.component";
import { useTranslation } from "react-i18next";
import WhatsappIcon from "./icons/shared/whatsapp-white.svg";
import PhoneIcon from "./icons/shared/phone-white.svg";
import BookingModal from "./components/booking-modal/booking-modal.component";
import SuperCard from "./pages/Super Card/superCard.component";
import Seasons from "./pages/Seasons/seasons.component";
import Near from "./pages/Near/near.component";

const App = () => {
  const { t } = useTranslation();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <div>
      {isTabletOrMobile && <MobileMenu handleMenuOpen={setIsMenuOpen} />}
      {isBigScreen && (
        <>
          <MenuComponent />
        </>
      )}
      {isModalOpen && (
        <BookingModal handleCloseModal={() => setIsModalOpen(false)} />
      )}
      {!isMenuOpen && (
        <>
          <div className="fixed-buttons">
            <div className="banner-cta">
              {isBigScreen && (
                <button
                  className="banner-cta--text"
                  onClick={() => setIsModalOpen(true)}
                >
                  {t(`cta.book`)}
                </button>
              )}
            </div>
            <div className="banner-cta">
              {isBigScreen && (
                <a
                  href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
                  target="_blank"
                >
                  <button
                    className="banner-cta--text second"
                    onClick={() => null}
                  >
                    {t(`cta.prenota`)}
                  </button>
                </a>
              )}
            </div>
          </div>
          <div className="banner__social">
            <a href="https://wa.me/4369911924384" target="_blank">
              <img
                src={WhatsappIcon}
                alt="phone icon"
                className="banner__social-icon"
              />
            </a>
            <a href="tel:4369911924384" target="_blank">
              <img
                src={PhoneIcon}
                alt="phone icon"
                className="banner__social-icon"
              />
            </a>
          </div>
          {isTabletOrMobile && <div>
            <a
              href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
              target="_blank"
            >
              <button className="banner-cta--text fixed" onClick={() => null}>
                {t(`cta.prenota`)}
              </button>
            </a>
          </div>}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="apartments" element={<Apartments />} />
            <Route path="card" element={<SuperCard />} />
            <Route path="seasons" element={<Seasons />} />
            <Route path="near" element={<Near />} />
            <Route path="contact" element={<Contact />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
