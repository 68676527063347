import { IAccordionItems } from "../shared/accordion/accordion.interface";

export const HOME_FAQ: IAccordionItems[] = [
  {
    header: "checkIn",
    content: "checkInDesc",
  },
  {
    header: "storno",
    content: "stornoDesc",
    linkTextDE: 'stornoTextDE',
    urlDE: 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agb-hotellerie.pdf',
    linkTextEN: 'stornoTextEN',
    urlEN: 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agbh-englisch.pdf',
    linkTextIT: 'stornoTextIT',
    urlIT: 'https://www.wko.at/oe/tourismus-freizeitwirtschaft/hotellerie/agbh-italienisch.pdf',
  },
  {
    header: "kurtaxe",
    content: "kurtaxeDesc",
  },
  {
    header: "haustiere",
    content: "haustiereDesc",
  },
  {
    header: "endreiningum",
    content: "endreiningumDesc",
  },
  {
    header: "zahlungen",
    content: "zahlungenDesc",
  },
  {
    header: "waschmoglichkeit",
    content: "waschmoglichkeitDesc",
  },
  {
    header: "nichtraucher",
    content: "nichtraucherDesc",
  },
];
