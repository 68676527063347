import "./near.style.scss";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Elfer from "../../icons/elfer.jpg";
import Elferhutte from "../../icons/elferhutte.jpg";
import Ghiacciaio from "../../icons/ghiacciaio.jpg";
import Highlight from "../../icons/highlight.jpg";
import Innsbruck from "../../icons/innsbruckerhutte.jpg";
import Issenang from "../../icons/issenangeralm.jpg";
import Karalm from "../../icons/karalm.jpg";
import Pinnisalm from "../../icons/pinnisalm.jpg";
import Meditativo from "../../icons/sentiero_meditativo.jpg";
import Wildeben from "../../icons/wildeben.jpg";
import { useTranslation } from "react-i18next";
import { NEAR_ITEMS } from "./near.constants";
import { useMediaQuery } from "react-responsive";

const Near = (): JSX.Element => {
  const { t } = useTranslation();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });

  return (
    <div className={isBigScreen ? "near" : "near mobile"}>
      <div className="map-container">
        <ul>
          <p className="map-title">{t("near.map-title")}</p>
          {NEAR_ITEMS.map((item) => (
            <li>{t(`near.${item}`)}</li>
          ))}
        </ul>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1SfYzACmp8yIYZt4FA1I2fdTOtKnmN1o&ehbc=2E312F"
          width={isBigScreen ? "640"  : "320"}
          height="480"
          allowFullScreen={true}
          loading="lazy"
        />
      </div>

      <h2>{t("near.text-title")}</h2>
      <h3 className="subtitle">{t("near.title-summer")}</h3>
      <h3>{t("near.subtitle-summer")}</h3>
      <div className="near-container">
        <Card sx={{ maxWidth: 345 }} className="div1">
          <CardMedia
            sx={{ height: 200 }}
            image={Issenang}
            title="Issenangeralm"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Issenangeralm
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.summer-one")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="div2">
          <CardMedia
            sx={{ height: 200 }}
            image={Pinnisalm}
            title="Pinnisalm"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Pinnisalm
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.summer-two")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="div3">
          <CardMedia
            sx={{ height: 200 }}
            image={Karalm}
            title="Karalm"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Karalm
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.summer-three")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="div4">
          <CardMedia
            sx={{ height: 200 }}
            image={Innsbruck}
            title="Innsbruckerhütte"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Innsbruckerhütte
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.summer-four")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 345 }} className="div5">
          <CardMedia
            sx={{ height: 200 }}
            image={Elferhutte}
            title="Elferhutte"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Elferhütte
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.summer-five")}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <h2>{t("near.brenda")}</h2>
      {isBigScreen ? (
        <div className="seasons">
          <div className="seasons__box">
            <img src={Meditativo} alt="sentiero meditativo" />
            <p>{t("near.brenda-one")}</p>
          </div>
          <div className="seasons__box">
            <img src={Wildeben} alt="Wildeben" />
            <p>{t("near.brenda-two")}</p>
          </div>
        </div>
      ) : (
        <div className="seasons-miniature">
          <div className="seasons-miniature__box">
            <img src={Meditativo} alt="sentiero meditativ" />
            <p>{t("near.brenda-one")}</p>
          </div>
          <div className="seasons-miniature__box">
            <img src={Wildeben} alt="Wildeben" />
            <p>{t("near.brenda-two")}</p>
          </div>
        </div>
      )}
      <h3 className="subtitle">{t("near.title-winter")}</h3>
      <h3>{t("near.subtitle-winter")}</h3>
      <div className={isBigScreen ? "pinnisalm-box" : "pinnisalm-mobile"}>
          <img src={Highlight} alt="Elfer highlight" />
          <p className="pinnisalm-box__text">{t("near.winter-one")}</p>
        </div>
      <div className="near-container">
        <Card sx={{ maxWidth: 400 }} className="div2">
          <CardMedia
            sx={{ height: 200 }}
            image={Elfer}
            title="Elfer"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("near.winter-two-title")}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.winter-two")}
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ maxWidth: 400 }} className="div3">
          <CardMedia
            sx={{ height: 200 }}
            image={Ghiacciaio}
            title="ghiacciaio"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {t("near.winter-three-title")}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {t("near.winter-three")}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <span className="photo-copyright">
        Photo Copyright: TVB Stubai Tirol, TVB Stubai Tirol/Andre Schönherr
      </span>
    </div>
  );
};

export default Near;
