import FamilySnow from "../../../../icons/family/family_snow.jpg";
import Apartment from "../../../../icons/apartments/serles/Serles-1.jpg";
import uuid from "react-uuid";
import Button from "../../button/button.component";
import { useTranslation } from "react-i18next";

import './card-responsive.style.scss'
import { useMediaQuery } from "react-responsive";
import { ICard } from "../card.interface";

export interface ICardProps {
  cards: ICard[]
}

const CardResposiveComponent = ({cards}: ICardProps): JSX.Element => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery({ query: "(max-width: 499px)" })

  return <div className={isMobile ? "card-responsive" : "card-responsive tablet"}>
      {cards.map((card) => {
            const img =
              card.title === "us"
                ? FamilySnow
                : card.title === "apartments"
                ? Apartment
                : Apartment;
            return (
              <div
                key={card.title}
                className="card-responsive-card"
              >
                <div className="blue-rect"></div>
                <div className="card-responsive__text">
                  <p className="card-responsive__text--title" key={uuid()}>
                    {t(`homepage.cards.${card.title}`)}
                  </p>
                  <img
                  src={img}
                  alt={t(`homepage.cards.${card.title}`)}
                  key={uuid()}
                />
                  <span className="card-responsive__text--description" key={uuid()}>
                    {t(`homepage.cards.${card.description}`)}
                  </span>
                  {card.linkTo && <Button
                    key={uuid()}
                    title={t(`homepage.cards.${card.cta}`)}
                    handleOnClick={() => null}
                    linkTo={card.linkTo}
                    className="card-responsive-button"
                    isFill
                  />}
                </div>
                <div className="black-rect"></div>
              </div>
            );
          })}
  </div>;
};

export default CardResposiveComponent;
