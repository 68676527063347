import { MENU_ITEMS } from "./menu.constants";
import uuid from "react-uuid";
import Button from "../../../shared/button/button.component";
import Logo from "../../../../icons/roasthof-logo-white.png";
import { useTranslation } from "react-i18next";
import Language from "../language/language.component";

import "./menu.style.scss";
import { IGenericMenu } from "../../header.interface";

const MenuComponent = (): JSX.Element => {
  const { t } = useTranslation();

  const menuClasses = (item: IGenericMenu): string => {
    if (item.linkTo === window.location.pathname.substring(1) || item.linkTo === window.location.pathname) return "menu--active";
    return "";
  };

  return (
    <div className="menu">
      {MENU_ITEMS.map((item) => {
        return (
          <>
            <Button
              key={uuid()}
              linkTo={item.linkTo}
              title={item?.name ? t(`menu.${item.name}`) : ""}
              handleOnClick={() => null}
              className={menuClasses(item)}
            >
              {item.img && <img key={uuid()} src={Logo} alt="logo" title="To the Homepage" />}
            </Button>
          </>
        );
      })}
      <Language />
    </div>
  );
};

export default MenuComponent;
