import { useTranslation } from "react-i18next";
import Whatsapp from "../../icons/shared/whatsapp.png";
import Phone from "../../icons/shared/phone.png";
import Instagram from "../../icons/shared/instagram.png";
import Logo from "../../icons/roasthof-logo-white.png";
// @ts-ignore
import policyDE from "../../icons/privacy/DE_privacyPolicy.pdf";
// @ts-ignore
import policyEN from "../../icons/privacy/EN_privacyPolicy.pdf";
// @ts-ignore
import policyIT from "../../icons/privacy/IT_privacyPolicy.pdf";



import "./footer.style.scss";
import { useMediaQuery } from "react-responsive";
import { LangugesEnum } from "../header/components/menu/menu.enum";

const Footer = (): JSX.Element => {
  const { t } = useTranslation();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  const getYear = (): number => {
    return new Date().getFullYear();
  };

  const getPrivacyPolicy = (): JSX.Element => {
    const selectedLang = localStorage.getItem("language");
    switch (selectedLang) {
      case LangugesEnum.DE:
        return <a href={policyDE} target="_blank">Privacy Policy</a>;
      case LangugesEnum.EN:
        return <a href={policyEN} target="_blank">Privacy Policy</a>;
      default:
        return <a href={policyIT} target="_blank">Privacy Policy</a>;
    }
  }

  const getCookiePolicy = (): JSX.Element => {
    const selectedLang = localStorage.getItem("language");
    switch (selectedLang) {
      case LangugesEnum.DE:
        return <a href="https://www.iubenda.com/privacy-policy/88209837/cookie-policy" target="_blank">Cookie Policy</a>;
      case LangugesEnum.EN:
        return <a href="https://www.iubenda.com/privacy-policy/77660735/cookie-policy" target="_blank">Cookie Policy</a>;
      default:
        return <a href="https://www.iubenda.com/privacy-policy/58936476/cookie-policy" target="_blank">Cookie Policy</a>;
    }
  }

  return (
    <>
      {isBigScreen && (
        <div className="footer-component">
          <div className="footer-map">
            <div className="footer-map__text">
              <p>{t("footer.title")}</p>
              <p>{t("footer.description")}</p>
              <p>{t("footer.family")}</p>
              <div className="footer-address">
                <p>{t("footer.via")}</p>
                <p>{t("footer.paese")}</p>
                <p>{t("footer.nazione")}</p>
              </div>
              <a href="tel:+4369911924384">+43 69911924384</a>
              <a href="mailto:roasthof@gmail.com">roasthof@gmail.com</a>
              <div className="footer-map__text-icons">
                <a href="https://wa.me/4369911924384" target="_blank">
                  <img
                    src={Whatsapp}
                    alt="whatsapp logo"
                    onClick={() => null}
                  />
                </a>
                <a href="tel:4369911924384" target="_blank">
                  <img src={Phone} alt="phone logo" onClick={() => null} />
                </a>
                <a
                  href="https://www.instagram.com/roasthof_apartments/"
                  target="_blank"
                >
                  <img
                    src={Instagram}
                    alt="instagram logo"
                    onClick={() => null}
                  />
                </a>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2715.1169120957265!2d11.327305276808234!3d47.11638022170034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479d46ae456c3d77%3A0xf1a97caff39a5488!2sPension%20Roasthof!5e0!3m2!1sit!2sit!4v1708796657063!5m2!1sit!2sit"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="footer-bar">
            <a href="/" target="_blank">
              <img
                src={Logo}
                alt="logo icon"
                onClick={() => null}
                className="footer-bar__logo"
              />
            </a>
            <p>
              Roasthof - {getPrivacyPolicy()} /
              {getCookiePolicy()} / Copyright {getYear()}®
            </p>
          </div>
        </div>
      )}
      {isTabletOrMobile && (
        <div className="footer-component-mobile">
          <div className="footer-map-mobile">
            <div className="footer-map-mobile__text">
              <p>{t("footer.title")}</p>
              <p>{t("footer.description")}</p>
              <div className="footer-mobile-address">
                <p>{t("footer.via")}</p>
                <p>{t("footer.paese")}</p>
                <p>{t("footer.nazione")}</p>
              </div>
              <a href="tel:+4369911924384">+43 69911924384</a>
              <a href="mailto:roasthof@gmail.com">roasthof@gmail.com</a>
              <div className="footer-map-mobile__text-icons">
                <a href="https://wa.me/4369911924384" target="_blank">
                  <img
                    src={Whatsapp}
                    alt="whatsapp logo"
                    onClick={() => null}
                  />
                </a>
                <a href="tel:4369911924384" target="_blank">
                  <img src={Phone} alt="phone logo" onClick={() => null} />
                </a>
                <a
                  href="https://www.instagram.com/roasthof_apartments/"
                  target="_blank"
                >
                  <img
                    src={Instagram}
                    alt="instagram logo"
                    onClick={() => null}
                  />
                </a>
              </div>
            </div>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2715.1169120957265!2d11.327305276808234!3d47.11638022170034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479d46ae456c3d77%3A0xf1a97caff39a5488!2sPension%20Roasthof!5e0!3m2!1sit!2sit!4v1708796657063!5m2!1sit!2sit"
              width="300"
              height="300"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="footer-bar-mobile">
            <a href="/" target="_blank">
              <img
                src={Logo}
                alt="logo icon"
                onClick={() => null}
                className="footer-bar-mobile__logo"
              />
            </a>
            <p>
              Roasthof - {getPrivacyPolicy()} /
              {getCookiePolicy()} / Copyright {getYear()}®
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
