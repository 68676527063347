import { ICard } from "./card.interface";

export const HOMEPAGE_CARDS: ICard[] = [
  {
    title: "apartments",
    description: "apartments-description",
    cta: "apartments-cta",
    linkTo: "apartments",
    path: "src/icons/apartments/serles/Serles",
    isTextRight: false,
  },
  {
    title: "us",
    description: "us-description",
    cta: "us-cta",
    linkTo: "near",
    path: "src/icons/family/family_snow",
    isTextRight: true,
  },
];
