import { useMediaQuery } from "react-responsive";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CarImg from "../../icons/contact/contactCar.jpg";
import Treno from "../../icons/contact/treno.jpg";
import emailjs from "@emailjs/browser";

import "./contact.style.scss";

const Contact = (): JSX.Element => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [msg, setMsg] = useState<string>("");

  const form = useRef<any>();

  const isBigScreen = useMediaQuery({ query: "(min-width: 1300px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_47ghcan",
        "template_g00jr4n",
        form.current,
        "dk8f09amlV1v0FCHh"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            form.current.reset();
            alert(t("booking.alert.ok"));
          }
        },
        (error) => {
          alert(t("booking.alert.ko"));
        }
      );
  };

  return (
    <>
      <div className={isBigScreen ? "contact" : "contact-miniature"}>
        <h2>{t("contact.subtitle")}</h2>
        <div className={isBigScreen ? "box" : "box-miniature"}>
          <div>
            <p className="subtitle">{t("contact.auto-title")}</p>
            <p>{t("contact.auto-desc")}</p>
          </div>
          <img src={CarImg} alt="auto" className="box__img" />
        </div>
        <div className={isBigScreen ? "box--reverse" : "box-miniature"}>
          <div className="box-container">
            <img src={Treno} alt="treno" className="box__img" />
            <div className="box-text">
              <p className="subtitle">{t("contact.bus-title")}</p>
              <p>{t("contact.bus-desc")}</p>
              <p>{t("contact.link")}</p>
              <div className="contact-link">
                <a
                  href="https://www.stubai.at/info-service/kostenlose-anreise/"
                  target="_blank"
                >
                  DE
                </a>
                /
                <a
                  href="https://www.stubai.at/en/info-service/free-arrival/"
                  target="_blank"
                >
                  EN
                </a>
                /
                <a
                  href="https://www.stubai.at/it/info-servizi/arrivo-gratuito/"
                  target="_blank"
                >
                  IT
                </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="contact-form-title">{t("contact.subtitle-form")} </h2>
          <form ref={form}>
            <div>
              <input
                type="text"
                name="name"
                placeholder={t("contact.name")}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                name="mail"
                placeholder={t("contact.mail")}
                onChange={(e) => setMail(e.target.value)}
              />
            </div>
            <input
              type="text"
              name="msg"
              className="msg"
              placeholder={t("contact.msg")}
              onChange={(e) => setMsg(e.target.value)}
            />
            <input
              type="submit"
              className="submit-btn"
              value={t("booking.send")}
              disabled={!name.length || !mail.length || !msg.length}
              onClick={sendEmail}
            />
          </form>
          {/* 
            - SOTTOTITOLO - 
            SPUNTA PRIVAC
            */}
        </div>
        <span className="photo-copyright">
          Photo Copyright: TVB Stubai Tirol, TVB
        Stubai Tirol/Andre Schönherr,
        </span>
      </div>
    </>
  );
};

export default Contact;
