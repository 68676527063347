export const NEAR_ITEMS = [
  "Bushaltestelle",
  "Supermarket",
  "Pizzeria",
  "Sportgeschäft",
  "Neustift",
  "Gletscher",
  "Schlick",
  "Serlesbahnen",
  "Elferbahnen",
  "Innsbruck",
];
