import uuid from "react-uuid";
import { IButton } from "./button.interface";

import "./button.style.scss";
import { classNames } from "../../../generic/class-names.utils";

export const Button = ({
  linkTo,
  title,
  handleOnClick,
  isSelected,
  isDisabled,
  className,
  children,
  isOutline,
  isFill,
}: IButton): JSX.Element => {
  const buttonClassNames = classNames({
    button: true,
    "button--selected": !!isSelected,
    "button--outline": !!isOutline,
    "button--fill": !!isFill,
    [className as string]: !!className,
  });

  return (
    <button
      className={buttonClassNames}
      key={uuid()}
      onClick={handleOnClick}
      // onClick={linkTo ? handleOnClick : () => null}
      disabled={!!isDisabled}
    >
      {linkTo ? <a href={linkTo}>{title}</a> : <span>{title}</span>}
      {children && <a href={linkTo} className="img">{children}</a>}
    </button>
  );
};

export default Button;
