import { IGenericMenu } from "../../header.interface";
import { LangugesEnum } from "./menu.enum";

export const MENU_ITEMS: IGenericMenu[] = [
  {
    name: "home",
    linkTo: "/",
  },
  {
    name: "apartments",
    linkTo: "apartments",
  },
  {
    name: "super-card",
    linkTo: "card",
  },
  {
    name: '',
    linkTo: "/",
    img: 'roasthof-logo.png',
  },
  {
    name: "valley",
    linkTo: "seasons",
  },
  {
    name: "near",
    linkTo: "near",
  },
  {
    name: "contacts",
    linkTo: "contact",
  },
];

export const LANGUAGES: string[] = [LangugesEnum.DE, LangugesEnum.EN, LangugesEnum.IT]