import HamburgerIcon from "../../../../icons/shared/hamburger.png";
import CloseIcon from "../../../../icons/shared/close.png";
import Logo from "../../../../icons/roasthof-logo-white.png";
import Whatsapp from "../../../../icons/shared/whatsapp.png";
import Instagram from "../../../../icons/shared/instagram.png";
import Phone from "../../../../icons/shared/phone.png";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";

import "./mobile-menu.style.scss";
import { useState } from "react";
import Language from "../language/language.component";
import { MENU_ITEMS } from "../menu/menu.constants";
import Button from "../../../shared/button/button.component";
import BookingModal from "../../../booking-modal/booking-modal.component";

const MobileMenu = ({ handleMenuOpen }: any): JSX.Element => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleClickMenu = () => {
    setIsOpen(!isOpen);
    handleMenuOpen(!isOpen);
  };

  if (isModalOpen)
    return (
      <BookingModal
        handleCloseModal={() => {
          setIsModalOpen(false);
        }}
      />
    );

  return (
    <div className="mobile-menu">
      <div className="mobile-menu__header">
        {isOpen ? (
          <span onClick={handleClickMenu}>
            <img
              src={CloseIcon}
              alt="close-icon"
              className="mobile-menu__icon"
            />
          </span>
        ) : (
          <span onClick={handleClickMenu}>
            <img
              src={HamburgerIcon}
              alt="hamburger-icon"
              className="mobile-menu__icon"
            />
          </span>
        )}
      </div>
      {isOpen && (
        <div className="mobile-menu__body">
          <img src={Logo} alt="logo" className="mobile-menu__logo" />
          <div className="mobile-menu__items">
            {MENU_ITEMS.map((item) => {
              if (!item.name) return "";
              return (
                <div key={uuid()}>
                  <div className="mobile-menu__items-item">
                    <Button
                      key={uuid()}
                      linkTo={item.linkTo}
                      title={t(`menu.${item.name}`)}
                      handleOnClick={handleClickMenu}
                      isSelected={false}
                    />
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mobile-menu__footer">
            <div className="mobile-menu-btn-container">
              <button
                className="cta-prenota"
                onClick={() => setIsModalOpen(true)}
              >
                {t(`cta.book`)}
              </button>
              <a
                href="https://direct.bookingandmore.com/accommodation/0a9bcad2-d7f0-4b80-a7e2-815a54a33859#/"
                target="_blank"
              >
                <button className="cta-prenota" onClick={() => null}>
                  {t(`cta.prenota`)}
                </button>
              </a>{" "}
            </div>
            <div className="mobile-menu__social">
              <div>
                <span>
                  <a href="https://wa.me/4369911924384" target="_blank">
                    <img
                      src={Whatsapp}
                      alt="whatsapp logo"
                      className="mobile-menu__icon--circle"
                      onClick={handleClickMenu}
                    />
                  </a>
                </span>
                <span>
                  <a href="tel:4369911924384" target="_blank">
                    <img
                      src={Phone}
                      alt="phone logo"
                      className="mobile-menu__icon--circle"
                      onClick={handleClickMenu}
                    />
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.instagram.com/roasthof_apartments/"
                    target="_blank"
                  >
                    <img
                      src={Instagram}
                      alt="instagram logo"
                      className="mobile-menu__icon--circle"
                      onClick={handleClickMenu}
                    />
                  </a>
                </span>
              </div>
              <Language />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
