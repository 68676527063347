import { useMediaQuery } from "react-responsive";
import CloseIcon from "../../icons/shared/close.png";
import { IBookingModalProps } from "./booking-modal.interface";
import "./booking-modal.style.scss";
import { useRef, useState } from "react";
import moment from "moment";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const BookingModal = ({
  handleCloseModal,
}: IBookingModalProps): JSX.Element => {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1299px)" });

  const form = useRef<any>();

  const addDaysToDate = (date: Date, days: number) => {
    const copy = new Date(date);
    copy.setDate(date.getDate() + days);
    return copy;
  };

  const placeholderDateStart = moment(new Date()).format("YYYY-MM-DD");
  const placeholderDateEnd = moment(
    addDaysToDate(new Date(placeholderDateStart), 1)
  ).format("YYYY-MM-DD");

  const [surname, setSurname] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [tel, setTel] = useState<number>();
  const [address, setAddress] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [prov, setProv] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [dateStart, setDateStart] = useState<string>(placeholderDateStart);
  const [dateEnd, setDateEnd] = useState<string>(placeholderDateEnd);
  const [adults, setAdults] = useState<number>();
  const [children, setChildren] = useState<number>();
  const [room, setRooom] = useState<string>("");

  const requiredFilters = [
    surname,
    name,
    mail,
    tel,
    address,
    city,
    prov,
    state,
    dateStart,
    dateEnd,
    adults,
    room
  ];

  const onChangeDateStart = (date: any) => {
    const newDate = moment(new Date(date.target.value)).format("YYYY-MM-DD");
    setDateStart(newDate);
    const newEndDate = addDaysToDate(new Date(date.target.value), 1);
    setDateEnd(moment(newEndDate).format("YYYY-MM-DD"));
  };

  const onChangeDateEnd = (date: any) => {
    const newDate = moment(new Date(date.target.value)).format("YYYY-MM-DD");
    setDateEnd(newDate);
  };

  const sendEmail = (e: any) => {    
    e.preventDefault();
    // 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', 'YOUR_FORM_ID', 'YOUR_PUBLIC_KEY'

    emailjs
      .sendForm(
        "service_47ghcan",
        "template_t3g1xck",
        form.current,
        "dk8f09amlV1v0FCHh"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            form.current.reset();
            alert(t('booking.alert.ok'));
          }
        },
        (error) => {
          alert(t('booking.alert.ko'));
        }
      );
  };

  const handleSelectRoom = (e: any) => {
    const { _, value } = e.target;
    setRooom(value)
  };

  return (
    <div className={isTabletOrMobile ? "modal-booking-miniature" : "modal-booking"}>
      <div className="modal-booking-container">
        <button className="icon-btn" onClick={handleCloseModal}>
          <img src={CloseIcon} alt="close icon" />
        </button>
        <form ref={form}>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.surname')}
                onChange={(value) => setSurname(value.target.value)}
                name="surname"
                required
              />
              <p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.name')}
                onChange={(value) => setName(value.target.value)}
                name="name"
                required
              />
              <p className='required-label'>{t('cta.required')}</p>
            </div>
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="email"
                placeholder={t('booking.email')}
                onChange={(value) => setMail(value.target.value)}
                name="mail"
                required
              />
              <p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <input
                type="number"
                placeholder={t('booking.telephone')}
                onChange={(value) => setTel(+value.target.value)}
                name="tel"
                required
              />
              <p className='required-label'>{t('cta.required')}</p>
            </div>
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.address')}
                onChange={(value) => setAddress(value.target.value)}
                name="address"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.city')}
                onChange={(value) => setCity(value.target.value)}
                name="city"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.prov')}
                onChange={(value) => setProv(value.target.value)}
                name="prov"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <input
                type="text"
                placeholder={t('booking.state')}
                onChange={(value) => setState(value.target.value)}
                name="state"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <p>Check-in</p>
              <input
                type="date"
                min={placeholderDateStart}
                defaultValue={placeholderDateStart}
                onChange={onChangeDateStart}
                name="dateStart"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <p>Check-out</p>
              <input
                type="date"
                min={dateEnd}
                defaultValue={dateEnd}
                onChange={onChangeDateEnd}
                name="dateEnd"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="number"
                placeholder={t('booking.adults')}
                onChange={(value) => setAdults(+value.target.value)}
                name="adults"
                required
              /><p className='required-label'>{t('cta.required')}</p>
            </div>
            <div className="modal-booking-element">
              <input
                type="number"
                placeholder={t('booking.children')}
                onChange={(value) => setChildren(+value.target.value)}
                name="children"
              />
            </div>
          </div>
            <p className="rooms-title">{t('booking.apartment')}</p><p className='required-label'>{t('cta.required')}</p>
          <div className="modal-booking-group room-group">
            <div className="rooms">
              <input type="radio" id="Burgstall" name="room" value="Burgstall" onChange={handleSelectRoom} /> 
              <p>Burgstall (1-3 {t('booking.people')})</p>
            </div>
            <div className="rooms">
              <input type="radio" id="Habicht" name="room" value="Habicht" onChange={handleSelectRoom}/>
              <p>Habicht (2-4 {t('booking.people')})</p>
            </div>
            <div className="rooms">
              <input type="radio" id="Kreuzjoch" name="room" value="Kreuzjoch" onChange={handleSelectRoom}/>
              <p>Kreuzjoch (2-4 {t('booking.people')})</p>
            </div>
            <div className="rooms">
              <input type="radio" id="Serles" name="room" value="Serles" onChange={handleSelectRoom}/>
              <p>Serles (2-6 {t('booking.people')})</p>
            </div>
            {/* <div className="rooms">
              <input type="radio" id="Room" name="room" value="Camera" onChange={handleSelectRoom}/>
              <p>{t('booking.room')} (1-2 {t('booking.people')})</p>
            </div> */}
          </div>
          <div className="modal-booking-group">
            <div className="modal-booking-element">
              <input
                type="textarea"
                placeholder={t('booking.message')}
                onChange={() => null}
                name="msg"
              />
            </div>
          </div>
          <div className="modal-booking-group">
            <input
              type="submit"
              className="submit-btn"
              value={t('booking.send')}
              disabled={requiredFilters.some((filter) => !filter?.toString().length)}
              onClick={sendEmail}
            />
          </div>
        </form>
        {/* check privacy policy */}
      </div>
    </div>
  );
};

export default BookingModal;
