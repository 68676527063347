import React from "react";
import ReactDOM from "react-dom";
import i18next from "i18next";
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import common_it from "./assets/translations/it/common-it.json";
import common_en from "./assets/translations/en/common-en.json";
import common_de from "./assets/translations/de/common-de.json";
import { LangugesEnum } from "./components/header/components/menu/menu.enum";

i18next.init({
  debug: true,
  lng: localStorage. getItem('language') || LangugesEnum.DE,
  fallbackLng: LangugesEnum.DE,
  resources: {
    deutsch: {
      translation: common_de,
    },
    english: {
      translation: common_en,
    },
    italiano: {
      translation: common_it,
    },
  },
});


ReactDOM.render(
  <React.StrictMode>
    <Router>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
